body::-webkit-scrollbar {
  display: none;
}
body.scrollable {
  overflow-y: auto;
}
button {
  outline: none !important;
  border: none;
}
img {
  border: none;
  height: auto;
  max-width: 100%;
  vertical-align: top;
}
.header-wrapper {
  padding: 0;
  position: sticky;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  .container-fluid {
    padding: 0px;
    .row {
      margin: 0px;
    }
  }
  .anticon-search {
    margin-bottom: 5px !important;
  }

  .navbar-nav {
    width: 100%;
  }
  .styleBtn_LoginMobile {
    color: #d61b22;
    font-weight: bold;
    border: 1px solid #d61b22;
    border-radius: 10px;
    padding: 4px 10px;
    font-size: 12px;
  }
  .header__top {
    padding: 0;
    position: sticky;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
  }

  .header__style {
    background-color: #fff;
    padding: 15px 0 !important;
    .style__info {
      display: flex;
      flex-direction: row;
    }
  }

  .header_content {
    box-shadow: none;
    padding: 0;
  }

  .style__navBar {
    position: relative;
    li {
      text-align: center;
      flex-grow: 1;
      position: relative;
      a {
        padding: 0.8rem;
        color: #12263f;
        transition: all 0.3s ease-in-out;
        outline: none;
        font-weight: 400;
        white-space: nowrap;
        transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
        font-size: 17px;
      }
      &:before {
        border-color: #e02735 !important;
        content: '';
        height: 0;
        position: absolute;
        width: 0;
        right: 0;
        bottom: -2px;
        border-bottom: 6px solid;
        transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
      }
      &:hover {
        a {
          color: #627792;
        }
        &::before {
          width: 100%;
          left: 0;
        }
      }
    }
    li.style__navBar__active {
      a {
        color: #e02735;
        font-weight: 600;
      }
      &:before {
        width: 100%;
        left: 0;
      }
    }
  }

  .header__info {
    background-color: #f3f3f3;
    padding: 0 10px;
    border-radius: 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
    height: auto;
    margin: 0px 10px;
  }

  .style__info {
    .header__info__content {
      font-size: 30px;
      margin-right: 10px;
      background-color: #d9d9d9;
      border-radius: 50%;
      color: white;
    }
  }

  .header__mini__menu {
    height: 60px;
    display: flex;
    font-size: 1rem;
    position: sticky;
    top: 0;
    background-color: #fff;
    left: 0;
    right: 0;
    z-index: 10;
    justify-content: space-between;
    align-items: center;
    .header__mini__nav {
      display: flex;
      align-items: center;
      padding: 0 10px 0 0;
      flex-direction: column;
      color: #fff;
      font-weight: 500;
      overflow: hidden;
      text-transform: uppercase;
    }
    i {
      color: black;
    }
  }

  .ant-input-affix-wrapper {
    background: #ffffff !important;
    border: 1px solid #b9b9b9 !important;
    border-radius: 10px 0px 0px 10px !important;
    border-right: 0px !important;
    box-shadow: none !important;
  }

  .ant-btn {
    background: #ffffff !important;
    border: 1px solid #b9b9b9 !important;
    border-left: 0px !important;
    border-radius: 0px 10px 10px 0px !important;
  }

  .btn.btn-flat {
    font-weight: 500;
    color: inherit;
    background-color: transparent;
  }

  .btn.btn-flat,
  .btn.btn-flat:not([disabled]):not(.disabled):active {
    box-shadow: none;
  }

  @media only screen and (max-width: 1381px) {
    .header-form {
      width: 200px !important;
    }
  }

  @media (min-width: 1200px) {
    .style__navBar a {
      padding: 0.8rem 1.2rem !important;
    }
  }

  @media (max-width: 1289px) {
    .header_navBar {
      padding: 0px !important;
    }
  }
  @media (max-width: 600px) {
    .hideSeach {
      display: none !important;
    }
  }
}
