.route-wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.route-wrapper > div {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
