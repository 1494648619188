.ant-drawer-body {
  padding: 6px !important;
}
.anticon {
  display: none;
}
img {
  border: none;
  height: auto;
  max-width: 100%;
  vertical-align: top;
}
.drawer-wrapper {
  .style_button {
    padding: 12px 16px;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    white-space: nowrap;
  }

  .style_button.style_creat {
    background: transparent;
    border: 1px solid #d61b22;
    border-radius: 8px;
    color: #d61b22;
    &:hover {
      color: red !important;
    }
  }
  .style-side-nav.wide {
    width: 15rem;
    transition-property: top, bottom, width;
    transition-duration: 0.2s, 0.2s, 0.35s;
    transition-timing-function: linear, linear, ease;
  }

  .style-side-nav > ul {
    max-height: 100vh;
  }

  .style-side-nav ul {
    list-style-type: none;
    list-style: none;
    padding-left: 0;
  }

  .style-side-nav ul li {
    padding: 0;
  }

  .right-aligned.style-side-nav.wide.slim {
    right: 0;
  }

  .style-side-nav.right-aligned {
    right: 0;
    left: auto;
    transform: translateX(100%);
  }

  .style-side-nav.fixed.right-aligned {
    right: 0;
    left: auto;
  }
  .style_content_bottom {
    flex: 1 auto;
    align-items: flex-end;
    display: flex;
  }
  .style_bg_menu_mobile {
    transition: all 0.3s ease-in-out !important;
    background-color: #fff;
    width: 100% !important;
    img {
      height: 100% !important;
      max-width: 100%;
    }
    .style_close {
      position: absolute;
      top: 7px;
      left: -15px;
      border: 0;
      button {
        background: transparent;
        outline: none;
        border: 0;
        color: #000;
        font-size: 13px;
        padding: 0.3rem 1rem;
        justify-items: center;
        justify-content: center;
        border-radius: 3px;
        div {
          background: transparent !important;
        }
      }
    }
    .style_SeachValue {
      margin: 20px auto;
    }
    .style_li_download {
      height: 100px !important;
      .style_download {
        background-color: #fff !important;
        margin-top: 20px;
      }
      .style_txtDown {
        background-color: #fff !important;
        color: black;
        text-align: left;
        font-size: 1.125rem;
      }
    }
    .style_list_group {
      display: flex;
      list-style-type: none;
      justify-content: space-around;
      background-color: #fff !important;
      align-items: center;
      text-align: center;
      li {
        padding: 0 7.5px;
        width: 50%;
        background-color: #fff !important;
        .style_image {
          background: unset !important;
          a {
            padding: 0;
            height: unset !important;
            line-height: unset !important;
            display: inline-block;
            img {
              margin: 0 !important;
              padding: 0 !important;
              max-width: 100% !important;
              height: 43px !important;
            }
          }
        }
      }
    }
    li.style_li_dangnhap {
      background: #fff;
      padding: 10px;
      margin: 24px 0;
      text-align: center;
    }
  }
  .right-side-slide-enter-done {
    opacity: 1;
    transform: translateX(0%) !important;
  }

  .scrollbar-container {
    position: relative;
    height: 100%;
  }

  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
  }

  .style-side-nav {
    .drawer__logo__wrapper {
      height: 4.75rem;
      a {
        height: 4.75rem;
        width: 100%;
        padding: 0;
        margin-left: auto;
      }
      img {
        padding: 13px 10px;
        height: auto;
        margin-left: auto;
      }
    }
  }
  .style_version {
    color: #ddd;
    padding-left: 20px;
  }

  .style_last_row {
    font-size: 14px;
  }

  .style-side-nav {
    a {
      display: block;
      font-size: 1rem;
      height: 48px;
    }
  }

  @media (max-height: 992px) {
    .style-side-nav .logo-wrapper1,
    .style-side-nav .logo-wrapper1 a {
      height: 60px;
    }

    .style-side-nav .logo-wrapper1 img {
      padding-top: 7%;
      padding-bottom: 7%;
    }
  }
}
