@charset "UTF-8";
/**
 * material-design-lite - Material Design Components in CSS, JS and HTML
 * @version v1.3.0
 * @license Apache-2.0
 * @copyright 2015 Google, Inc.
 * @link https://github.com/google/material-design-lite
 */
@charset "UTF-8";

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

.wapper_page_title {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.wapper_page_content {
  width: 100%;
  color: #272727 !important;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  overflow: auto;
}

.page_style p {
  line-height: 35px;
  font-size: 14px;
}

a {
  color: #272727;
  font-weight: bolder;
  text-decoration: none;
}

a:hover {
  color: #272727;
  font-weight: bolder;
  text-decoration: none;
}

.page_content {
  margin: 30px auto;
  padding: 20px 0;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c1c1c1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1;
}

@media screen and (max-width: 991px) {
  .page_content {
    margin-top: 55px !important;
  }
}

.MuiButtonBase-root {
  height: 100%;
}