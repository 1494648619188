@charset "UTF-8";
/**
 * material-design-lite - Material Design Components in CSS, JS and HTML
 * @version v1.3.0
 * @license Apache-2.0
 * @copyright 2015 Google, Inc.
 * @link https://github.com/google/material-design-lite
 */

@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&display=swap');
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
html {
  color: rgba(0, 0, 0, 0.87);
}
/* hide scrollbar but allow scrolling */
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none !important;
}
/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; /* Firefox */
}
.HomePage__backgroundImg {
  background-repeat: no-repeat !important;
  background-position: top !important;
  background-size: cover !important;
  max-width: 1920px;
  margin: auto;
  min-height: 200px;
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardForm {
  background-color: #ffff;
  user-select: none;
  padding: 15px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(4px);
  border-radius: 6px;
  overflow: visible;
}
.MuiAccordion-rounded {
  margin-bottom: 10px !important;
  border-radius: 10px !important;
}
.MuiAccordion-root:before {
  opacity: 0 !important;
}
.MuiPickersBasePicker-pickerView {
  background-color: white;
}

.MuiDialog-container {
  height: 100vh !important;
}
button:disabled,
button[disabled]{
  background-color: #cccccc ;
  color: #666666 ;
  cursor: no-drop ;
} 
.MuiFormHelperText-root.Mui-error {
  display: none;
}

.MuiFormControlLabel-root.Mui-disabled {
  cursor: no-drop !important;
}

button {
  height: 40px;
  cursor: pointer;
}
.cursor-pointer{
  cursor: pointer;

}
button:hover {
  cursor: pointer;
}

.font-bold {
  font-weight: bold;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
.border-dashed{
  border-bottom: 1px dashed #D9D9D9
}
.textBold {
  margin-top: 2px;
  color: #666666;
  font-weight: bold;
}
.fontBold{
  font-weight: bold;
}
.regisSuccess {
  font-weight: bold;
  font-size: 12px;
  color: #4ae001;
  padding: 4px 16px;
  border: 1px solid #4ae001;
  border-radius: 38px;
}
.regisWait {
  font-weight: bold;
  font-size: 12px;
  color: #60B7FF;
  padding: 4px 16px;
  border: 1px solid #60B7FF;
  border-radius: 38px;
}
.regisCancel {
  font-weight: bold;
  font-size: 12px;
  color: #EB262E;
  padding: 4px 16px;
  border: 1px solid #EB262E;
  border-radius: 38px;
}
.regisUndefined {
  font-weight: bold;
  font-size: 12px;
  color: black;
  padding: 4px 16px;
  border: 1px solid black;
  border-radius: 38px;
}
.notification {
  background-color: #FFF5F5;
  border: 1px dashed #D0004B;
  border-radius: 9px;
  padding: 12px;
  color: #EB262E;
}
.hidden {
  display: none !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.h7{
  font-size: 14px;
}
.font-medium{
  font-size: 18px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}

.clearfix:after {
  clear: both;
}

.text-red {
  color: #D61B22
}

.button-red {
  padding: 8px 20px;
  color: white !important;
  background-color: #d61b22 !important;
  border-radius: 10px !important;
  border: none !important;
  font-size: 14px
}

.button-white {
  padding: 8px 20px;
  color: #bd002a !important;
  background-color: transparent !important;
  border-radius: 10px !important;
  border: 1px solid #bd002a !important;
  font-size: 14px
}

@media print {

  *,
  *:before,
  *:after,
  *:first-letter {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

a,
.mdl-accordion,
.mdl-button,
.mdl-card,
.mdl-checkbox,
.mdl-dropdown-menu,
.mdl-icon-toggle,
.mdl-item,
.mdl-radio,
.mdl-slider,
.mdl-switch,
.mdl-tabs__tab {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html {
  width: 100%;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

body {
  width: 100%;
  min-height: 100%;
}

main {
  display: block;
}

*[hidden] {
  display: none !important;
}

html,
body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0px;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
  font-size: 0.6em;
}

h1 {
  font-size: 56px;
  line-height: 1.35;
  letter-spacing: -0.02em;
  margin: 24px 0;
}

h1,
h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

h2 {
  font-size: 45px;
  line-height: 48px;
}

h3 {
  font-size: 26px;
  line-height: 40px;
}

h3,
h4 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

h4 {
  font-size: 24px;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
}

h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
}

h5,
h6 {
  font-family: 'Roboto', sans-serif;
  margin: 24px 0 16px;
}

label {
  display: block;
  padding: 0px;
  margin: 0px;
}

h6,
div {
  font-size: 16px;
  letter-spacing: 0.04em;
}

h6,
p {
  color: #a6a6a6;
  font-weight: 400;
  line-height: 24px;
}

p {
  font-size: 14px;
  letter-spacing: 0;
}

a {
  color: rgb(255, 64, 129);
  font-weight: 500;
}

blockquote {
  font-family: 'Roboto', sans-serif;
  position: relative;
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: 0.08em;
}

blockquote:before {
  position: absolute;
  left: -0.5em;
  content: 'â€œ';
}

blockquote:after {
  content: 'â€';
  margin-left: -0.05em;
}

mark {
  background-color: #f4ff81;
}

dt {
  font-weight: 700;
}

address {
  font-size: 12px;
  line-height: 1;
  font-style: normal;
}

address,
ul,
ol {
  font-weight: 400;
  letter-spacing: 0;
}

ul,
ol {
  font-size: 14px;
  line-height: 24px;
}

body {
  background-color: #eef1f5;
  padding: 0 !important;
  margin: 0 !important;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.7;
  color: rgba(0, 0, 0, 0.87);
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  right: 0;
}

.bottom-0 {
  right: 0;
}

.text-error {
  color: red;
  font-size: 0.8rem;
}

.text-warring {
  color: #faad14;
  font-size: .8rem;
}

.MuiInputBase-root input[readOnly],
.MuiInputBase-root.Mui-disabled {
  background-color: #D9D9D9;
  cursor: no-drop;
}
