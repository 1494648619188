.footer-wrapper {
  .style_footer {
    overflow: hidden;
    .style_footer_inner {
      background-color: #f8fafd;
      padding: 2rem 0;
      border-top: 1px solid #dfe3eb;
    }
    .style_footer_copy_right {
      background-color: #d61b22;
      color: #fff;
      font-size: 14px;
      text-align: center;
      padding: 1rem 0;
    }
    .style_item_info {
      overflow: hidden;
      li {
        border: 0 !important;
        border-radius: 0 !important;
        background-color: transparent;
        font-size: 14px;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding: 5px 0;
        a {
          color: inherit;
          &:focus {
            color: #0252cd;
          }
          &:hover {
            color: #0252cd;
          }
        }
      }
    }
    .style_icons {
      flex-direction: unset;
      flex-wrap: wrap;
      li {
        flex: 0 1 50%;
        margin-bottom: 5px;
      }
      a {
        display: block;
        padding: 0 10px;
        img {
          width: 100%;
        }
      }
    }
    .style_text {
      color: #d61b22 !important;
    }
    .style_logo_footer {
      width: 100%;
      max-width: 160px !important;
    }
  }

  .list-group {
    .list-group-item:first-child {
      border-top-left-radius: 0.125rem;
      border-top-right-radius: 0.125rem;
    }
    .list-group-item:last-child {
      border-bottom-left-radius: 0.125rem;
      border-bottom-right-radius: 0.125rem;
    }
  }
  @media only screen and (max-width: 992px) {
    .style_footer {
      .style_footer_inner {
        text-align: left;
      }
      .style_icons {
        max-width: 400px;
        margin: 0 auto;
        justify-content: center;
      }
    }
  }

  @media (max-width: 600px) {
    .style_footer .style_footer_copy_right li,
    .style_footer .style_icons li {
      flex: 0 1 40%;
    }
  }
}
