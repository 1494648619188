.seach_wrapper {
  ::placeholder {
    font-size: 0.9rem;
  }
  width: 100%;
  margin-top: 0px;
  .ant-input-affix-wrapper {
    background: #ffffff !important;
    border: 1px solid #b9b9b9 !important;
    border-radius: 8px 0px 0px 8px !important;
    border-right: 0px !important;
    box-shadow: none !important;
  }
  .ant-btn {
    background: #ffffff !important;
    border: 1px solid #b9b9b9 !important;
    border-left: 0px !important;
    border-radius: 0px 8px 8px 0px !important;
    span {
      margin-bottom: 3px;
    }
  }
}
