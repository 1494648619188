/* ----------------------------------------------
 * Generated by Animista on 2022-9-20 14:56:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-right {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide-right {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slide-right {
  -webkit-animation: slide-right 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-right 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
